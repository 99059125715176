<template>
	<div class="Correct">
		<div style="position: fixed;bottom: 40px;padding: 20px;">
			<div style="margin-top: 20px;">
				<el-button size="mini" @click="previousPage()">上一页</el-button>
			</div>
			<div style="margin-top: 20px;">
				<el-button size="mini" @click="nextPage()">下一页</el-button>
			</div>
			<div style="margin-top: 20px;" v-if="this.statues != '家长'">
				<el-button size="mini" @click="evaluate()">评价</el-button>
			</div>
		</div>
		<div style="position: fixed;right: 60px;bottom: 60px;">
			<div style="margin-top: 20px;">
				<el-button size="mini" @click="previousPage()">上一页</el-button>
			</div>
			<div style="margin-top: 20px;">
				<el-button size="mini" @click="nextPage()">下一页</el-button>
			</div>
			<div style="margin-top: 20px;" v-if="this.statues != '家长'">
				<el-button size="mini" @click="evaluate()">评价</el-button>
			</div>
		</div>
		<Ceshi ref="Ceshi" canvasIdName="canvasIdName"></Ceshi>
	</div>
</template>
<script type="text/javascript" src="./uniUtil.js"></script>
<script>
	import {
		showLoading,
		hideLoading
	} from '../../utils/Loading'
	import {
		getCorrectionFromPlan
	} from '../../api/Correct.js'
	import {
		queryListByDictValue,queryPlanById
	} from '../../api/base.js'
	import Revision from "../../components/Revision.vue"
	import Ceshi from '../../components/Ceshi/index.vue'
	import baseJs from '../../utils/base/base.js'
	import teachlanguagechian from '../../assets/json/chian.json'
	export default {
		name: 'Class',
		data() {
			return {
				pageId: null,
				pageIds: null, //
				cur_page: 0, //第几页
				img: null, //图片地址
				userId: null, //用户Id
				img_list: null, //
				teachId: null, //老师Id
				teachLoginData: null,
				setId: null,
				planId: null,
				classId: null,
				canvesWight: 1200, //canvse 宽
				planData: null, //作业相关信息
				deviationStutes: null, //偏移状态 老师偏移还是学生偏移
				statues:null,
				teachlogin:null,  //新加的老师登录信息
				planKind:null
			}
		},
		components: {
			Ceshi
		},
		watch: {
			
		},
		mounted() {
			// this.uniappMessage()
			this.$store.state.teachlanguagedata=teachlanguagechian
			//获取本地的url
			var search = window.location.href;
			//分解url的参数重新组合
			var data = baseJs.queryURLParams(search);
			// console.log(data);
			this.classId=data.teachclass_id
			this.planId=data.setdataId
			this.setId=data.setId
			this.teachId=data.userID
			this.userId=data.studentId
			//微信小程序传过来的planKind
			this.planKind = decodeURIComponent(decodeURI(data.planKind)),
			console.log(this.planKind ,'这是');
			localStorage.setItem('wxPlanKind',this.planKind)
			this.teachlogin = {
				account :data.teachLoginAccount,
				type : data.teachLoginType
			}
			localStorage.setItem('wxTeachLogin',JSON.stringify( this.teachlogin ))
			this.statues=decodeURIComponent(decodeURI(data.statues))
			this.userToken = decodeURIComponent(decodeURI(data.userToken)).replace(/ /g, "+")
			localStorage.setItem("teachuserToken", JSON.stringify(this.userToken))
			// console.log(this.teachlogin);
			this.$nextTick(()=>{
				this.QueryPlanById()
			})	
		},
		methods: {
			  // function 调用uniapp的 方法
			  
	postMsg(imgUrl) {
      uni.postMessage({
        data: {
          action: imgUrl,
        },
      })
    },
			// 评价
			evaluate() {
				this.$refs.Ceshi.evaluate()
			},
			QueryPlanById(){
				queryPlanById({id:this.planId}).then(res=>{
					this.planData=res.data.result
					this.teachLoginData=res.data.result
					this.get_correction_from_plan()
				})
			},
			// 获取所有页面
			get_correction_from_plan() {
				getCorrectionFromPlan({
					"plan_id": this.planId,
					"userID": this.teachId,
					"setId": this.setId,
					"class_id": this.classId
				}).then(response => {
					this.corrected_users = response.data.corrected_users;
					this.unjoin_users = response.data.unjoin_users;
					this.pageId = response.data.pageId;
					this.pageIds = response.data.pageIds;
					this.cur_page = response.data.cur_page;
					this.img_list = response.data.img_list;
					this.img = response.data.img_src;
					this.postMsg(this.img)
					// this.uniappMessage()
					this.$refs.Ceshi.init()
				})
			},
			//上一页
			previousPage() {
				this.$refs.Ceshi.previousPage()
				this.sendmessage(this.img)
			},
			//下一页
			nextPage() {
				this.$refs.Ceshi.nextPage()
				this.sendmessage(this.img)
			},
		}
	}
</script>

<style></style>
